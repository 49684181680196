
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');


html,
body,
#root,
.App,
.content {
  height:100%;
  width:100%;
  font-family: "Work Sans", sans-serif;
  text-align: center;
  background-color: #534d4d !important;
}
@media (max-width: 600px) {
  .App, .content {
    height: 100vh; /* Adjust to 100% of the viewport height */
    /* overflow: hidden;  Prevent scrolling */
  }
}

::-webkit-scrollbar-track {
    background:#e0e0e0;
}
::-webkit-scrollbar-thumb {
  background:#888;
}
::-webkit-scrollbar-track:hover {
  background:#555;
}


#logo {
  width:80%;
  height: 80px;
  object-fit: contain;
}

label {
  display: block;

}
#driver-text {
  color:#67814e;
}

#noob-header {
  margin-top:25px;
  color: white;

}
#signup-header {
  color:white;
}
#sign-up-btn {
  padding:1px;
}

#login-form {
  width:70%;
  margin-left:15%;
}

#dropoff-input {
  padding-bottom:16px;

}

.attribution-control {
  font-size: 1px;
  bottom: 0;
  right: 0;
}
.maplibregl-ctrl-bottom-right {
display:none;}

/* Add this to your existing .blink class */
.blink::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent overlay */
  animation: blink 1s infinite;
}

/* Ensure the MapPreview container has a relative position */
.map-container {
  position: relative;
  overflow: hidden;
}
@keyframes blink-first {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes blink-second {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

@keyframes blink-third {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.blink-first {
  animation: blink-first 1.6s infinite;
}
.blink-first:hover {
  border: 2px solid #70a569; /* Outline the border on hover */
  cursor: pointer;
}

.blink-second {
  animation: blink-second 1.6s infinite;
  animation-delay: 0.2s; /* Delays the start of the animation */
}
.blink-second:hover {
  border: 2px solid #6d9e67; /* Outline the border on hover */
  cursor: pointer;
}

.blink-third {
  animation: blink-third 1.6s infinite;
  animation-delay: 0.4s; /* Delays the start of the animation */
}
.blink-third:hover {
  border: 2px solid #558555; /* Outline the border on hover */
  cursor: pointer;
}

/* CSS for highlighting the section on hover */
.section-hover:hover {
  background-color: #4a7944; /* Change the background color on hover */
  cursor: pointer; /* Change the cursor to indicate it's clickable */
}

/* CSS for outlining the border on hover */
.border-hover:hover {
  border: 2px solid #0044cc; /* Outline the border on hover */
  cursor: pointer;
}



@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.button-base:hover img {

}

#loginBtn:hover {
  background-color: "#3a594f"!important
}




/* Hide the attachment button */
.cs-button--attachment {
  display: none !important;
}

/* Style the send button for a sleek look */
/* Chat Pane Container */
/* Chat Pane Container */
.cs-main-container {
  backdrop-filter: blur(10px) !important; /* Frosted glass effect */
  background-color: white!important;
  position: fixed!important;
  bottom: 1!important;
  margin-bottom: env(safe-area-inset-bottom, 30px); /* Fallback value of 20px */

 
}
.cs-conversation-header {
  background: linear-gradient(145deg, #819C9A, #A1B8B7)!important;

 
  
}



.cs-conversation-header__user-name {
  background: linear-gradient(145deg, #819C9A, #A1B8B7)!important;

}
.cs-conversation-header__info {
  background: linear-gradient(145deg, #819C9A, #A1B8B7)!important;
  font-weight: bold!important;
}
/* Chat Container */
.cs-chat-container {
  backdrop-filter: blur(10px) !important; /* Frosted glass effect */

  background-color: #f0f0f0!important;

}

/* Message List */

/* Message Bubbles */
.cs-message--incoming .cs-message__content,
.cs-message--outgoing .cs-message__content {
  background-color: white;
  color: #ffffff;
  border-radius: 18px;

}

.cs-message--incoming .cs-message__content {
  background: linear-gradient(145deg, #BCDEC6, #DADEDB)!important;
}

.cs-message--outgoing .cs-message__content {
  background: linear-gradient(145deg, #CED5DE, #B5CCDE)!important;
}

.cs-message-input {
  background-color: #f5f5f5!important; /* Lighter grey for the input box */
  border-top: 1px solid rgba(0, 0, 0, 0.1)!important;
  font-size: 18px!important;

}

.cs-message-input__content-editor {
  background-color: #f5f5f5!important; /* Matching the input box color */
  color: #707360!important;
  border-radius: 6px!important;/* Consistent border-radius with the input box */
  padding: 8px 16px!important;
}




/* Adjusted expanded accordion style */
.css-1oqimao.Mui-expanded {
  min-height: 30px!important;
  margin: 13px 0px!important;
}

.scrollbar-container .cs-message-input__content-editor-container .ps {
  background-color: #ffffff!important;
}
.cs-message-input__content-editor-wrapper {
  background-color: #f5f5f5!important; /* Matching the input box color */
}
.maplibregl-map {
  overflow: hidden!important;
  height: 270px !important;
}

.pac-container {
  width: 100%!important; /* Removed !important */
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  left: 0%;
}

.pac-item {
  border-bottom: 1px solid #e0e0e0;
  color: #333;
  font-size: 16px;
  padding: 10px; /* Removed % and !important */
  left: 0%!important;
}

.pac-item:hover,
.pac-item.pac-item-selected,
.pac-item:active {
  background-color: #eaeaea;
  color: #212121;
}

.pac-item-query {
  font-weight: bold;
  color: #769690;
}

.pac-item-secondary-text {
  color: #757575;
}

#back-booking {
  position:'absolute'!important;
  margin-top: '-70px'!important;
}

#request-ride-button {
  padding-top: env(safe-area-inset-top, 38px);
}

#notch-adjust {
  padding-top: env(safe-area-inset-top, 18px);
}



.gradient-text {
  /* Fallback: Set a background color. */
  background-color: rgb(5, 4, 4);
  
  /* Create the gradient. */
  background-image: linear-gradient(45deg, #509b88, #39476d);
  
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.spinner {
  border: 16px solid #353a35; /* Light grey */
  border-top: 16px solid #334653; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: '#001623'; /* Optional: Add a semi-transparent background */
}

.spinner-container-bookings {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:'4px';
  height: 100%;
  width: 100%;
}
